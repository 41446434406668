import { combineReducers } from 'redux'
import billingReducer from './billingReducer'
import commonReducer from './commonReducer'
import inboxReducer from './inboxReducer'
import profileReducer from './profileReducer'

const rootReducer = combineReducers({
  inbox: inboxReducer,
  billing: billingReducer,
  common: commonReducer,
  profile: profileReducer
})

export default rootReducer
