export const DATA_TYPES = {
  number: 'number',
  string: 'string',
  function: 'function',
  object: 'object',
  boolean: 'boolean'
}

export const MESSAGE_INPUT_CONFIG = {
  basic: 'basic',
  advanced: 'advanced',
  advancedWithSummary: 'advancedWithSummary',
  advancedWithAI: 'advancedWithAI'
}

export const BADGE_VARIANTS = {
  basic: 'basic',
  error: 'error'
}

export const TAB_VARIANTS = {
  chip: 'chip',
  pane: 'pane'
}

export const BUTTON_VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  icon: 'icon',
  text: 'text',
  fullWidth: 'fullWidth',
  center: 'center'
}

export const MESSAGE_VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  internal: 'internal'
}

export const MESSAGE_SENDER_TYPES = {
  staff: 'staff',
  contact: 'contact'
}

export const MESSAGE_SCOPE_TYPES = {
  internal: 'internal',
  external: 'external'
}

export const ORIENTATION_TYPES = {
  vertical: 'vertical',
  horizontal: 'horizontal'
}

export const MODAL_VARIANTS = {
  center: 'center',
  bottomRight: 'bottomRight'
}

export const CONVERSATION = {
  inbox: 'open',
  spam: 'spam',
  closed: 'closed',
  draft: 'draft',
  settings: 'settings',
  unassigned: 'unassigned'
}

export const LOCALSTORAGE = {
  currentUser: 'currentUser',
  localization: 'localization'
}

export const COOKIE_KEYS = {
  token: 'token',
  timezone: 'timezone',
  country: 'country',
  centerId: 'center_id',
  userName: 'user_name',
  userEmail: 'user_email',
  userBusiness: 'user_business',
  businessId: 'business_id'
}

export const API_METHODS = {
  get: 'get',
  post: 'post',
  patch: 'patch',
  delete: 'delete'
}

export const MESSAGE_STATUS = {
  created: 'created',
  sent: 'sent',
  delivered: 'delivered',
  read: 'read'
}

export const BOOKINGS_TYPE = {
  future: 'future'
}

export const MEMBERSHIP_TYPES = {
  membershipPlan: 1,
  creditPack: 2,
  subscription: 3,
  topupPack: 4
}

export const CREDIT_PACKS_STATUS = {
  active: 1,
  expired: 2,
  upcoming: 3,
  freezed: 4,
  billingIssue: 5,
  cancelled: 6,
  cancellationScheduled: 7,
  pauseScheduled: 8
}

export const SUBSCRIPTION_STATUS = {
  active: 1,
  upcoming: 2,
  expired: 3,
  cancelled: 4,
  cancellationScheduled: 5,
  paused: 6
}

export const LOADER_VARIANTS = {
  primary: 'primary',
  secondary: 'secondary'
}

export const BANNER_VARIANTS = {
  error: 'error',
  primary: 'primary'
}

export const AI_OPTIONS_TYPES = {
  summarize: 'summarize',
  customPrompt: 'custom_prompt',
  moreFormal: 'more_formal',
  moreFriendly: 'more_friendly',
  rephrase: 'rephrase',
  expand: 'expand'
}

export const COMMUNICATION_SETTINGS_OPTIONS = {
  marketingSMS: 'marketing_sms',
  transactionalSMS: 'transactional_sms'
}
