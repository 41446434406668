import { PROFILE_DETAILS } from '../types/profileTypes'

const initialState = {
  openedProfile: {}
}

const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PROFILE_DETAILS:
      return { ...state, openedProfile: payload }
    default:
      return state
  }
}

export default profileReducer
