import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { API_MAX_RETRIES } from '@/services/constants'
import { get } from '@/services/network'

/**
 * Uses `useQuery` hook from `react-query` to fetch data from the specified `url`
 * with the provided `params`. Caches the response and manages loading and error state.
 *
 * @param {string} url - The URL to fetch data from.
 * @param {object} params - The parameters to include in the request.
 * @returns {object} - The query object returned by `useQuery`.
 */
export const useGet = (
  url,
  {
    params = {},
    options = {},
    dependencies = [],
    importantDependencies = [],
    baseURL
  } = {}
) => {
  const [isEnabled, setEnabled] = useState(false)

  useEffect(() => {
    const hasAllImportantDependencies = importantDependencies.every(
      (dep) => typeof dep !== 'undefined' && dep !== null
    )

    if (hasAllImportantDependencies) {
      setEnabled(true)
    }
  }, [importantDependencies])

  return useQuery({
    queryKey: [url, ...dependencies],
    queryFn: async () => {
      const data = await get(url, params, baseURL)
      return data
    },
    retry: API_MAX_RETRIES,
    enabled: importantDependencies.length > 0 ? isEnabled : false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options
  })
}
