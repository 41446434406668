import { arrayToObject } from '@/services/utils'
import { ALL_STAFFS, PERMISSIONS } from '../types/commonTypes'

const initialState = {
  staffs: {},
  permissions: {}
}

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ALL_STAFFS:
      return { ...state, staffs: arrayToObject(payload) }
    case PERMISSIONS:
      return { ...state, permissions: arrayToObject(payload, 'name') }
    default:
      return state
  }
}

export default commonReducer
