import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BUTTON_VARIANTS } from '@/services/enums'
import { classNames } from '@/services/utils'
import Spinner from '../spinner'
import styles from './index.module.scss'

const Button = ({
  icon,
  title,
  onClick,
  isDisabled = false,
  isLoading = false,
  className,
  variants = BUTTON_VARIANTS.primary,
  children,
  ...props
}) => {
  const styledVariants = useMemo(() => {
    if (Array.isArray(variants))
      return variants.map((variant) => styles[variant]).join(' ')

    return styles[variants]
  }, [variants])

  return (
    <button
      disabled={isDisabled}
      onClick={onClick}
      className={classNames(className, styles.button, styledVariants)}
      {...props}
    >
      {isLoading ? (
        <span className={styles.loader}>
          <Spinner buttonVariant={variants} />
        </span>
      ) : (
        <>
          {icon}
          {title && <span>{title}</span>}
          {children}
        </>
      )}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  variants: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(BUTTON_VARIANTS)))
  ])
}

export default Button
