import { configureStore } from '@reduxjs/toolkit'
import config from '@/config'
import rootReducer from './reducers'

const store = configureStore({
  reducer: rootReducer,
  devTools: !config.IS_PRODUCTION
})

export default store
