import BaseIcon from './Base'

const IconCart = ({ height = 16, width = 16, className }) => {
  return (
    <BaseIcon
      width={width}
      height={height}
      viewBoxHeight={16}
      viewBoxWidth={16}
      className={className}
    >
      <g clipPath="url(#clip0_743_16854)">
        <path
          d="M1.33325 1.33325H2.20403C2.36804 1.33325 2.45005 1.33325 2.51604 1.36341C2.5742 1.38999 2.62348 1.43274 2.65802 1.48655C2.69721 1.54761 2.70881 1.6288 2.732 1.79116L3.04754 3.99992M3.04754 3.99992L3.7488 9.15419C3.83779 9.80827 3.88228 10.1353 4.03865 10.3815C4.17644 10.5984 4.37397 10.7709 4.60749 10.8782C4.87249 10.9999 5.20255 10.9999 5.86266 10.9999H11.5679C12.1963 10.9999 12.5105 10.9999 12.7672 10.8869C12.9936 10.7872 13.1878 10.6265 13.3281 10.4227C13.4872 10.1917 13.546 9.88303 13.6636 9.26576L14.546 4.63305C14.5874 4.41579 14.6081 4.30716 14.5781 4.22225C14.5518 4.14777 14.4999 4.08504 14.4316 4.04526C14.3538 3.99992 14.2432 3.99992 14.0221 3.99992H3.04754ZM6.66659 13.9999C6.66659 14.3681 6.36811 14.6666 5.99992 14.6666C5.63173 14.6666 5.33325 14.3681 5.33325 13.9999C5.33325 13.6317 5.63173 13.3333 5.99992 13.3333C6.36811 13.3333 6.66659 13.6317 6.66659 13.9999ZM11.9999 13.9999C11.9999 14.3681 11.7014 14.6666 11.3333 14.6666C10.9651 14.6666 10.6666 14.3681 10.6666 13.9999C10.6666 13.6317 10.9651 13.3333 11.3333 13.3333C11.7014 13.3333 11.9999 13.6317 11.9999 13.9999Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_743_16854">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  )
}

export default IconCart
