import PropTypes from 'prop-types'
import { classNames } from '@/services/utils'
import styles from './index.module.scss'

const BaseIcon = ({
  width,
  height,
  viewBoxHeight,
  viewBoxWidth,
  rotate = 0,
  className,
  children
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${viewBoxWidth || width} ${viewBoxHeight || height}`}
      width={width}
      height={height}
      className={classNames(styles.icon, className, styles[`rotate-${rotate}`])}
      fill="none"
    >
      {children}
    </svg>
  )
}

BaseIcon.propTypes = {
  children: PropTypes.any.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rotate: PropTypes.number,
  viewBoxHeight: PropTypes.number,
  viewBoxWidth: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default BaseIcon
