import {
  ADD_TEMPLATE,
  ALL_TEMPLATES,
  DELETE_TEMPLATE,
  EDIT_TEMPLATE,
  IS_COMPOSE_MESSAGE_MODAL,
  OPENED_CONVERSATION,
  OPENED_DRAFT,
  SETUP_CHANNELS,
  UNREAD_COUNT
} from '../types/inboxTypes'

const initialState = {
  openedConversation: {},
  templates: [],
  setupChannels: null,
  unreadCount: 0,
  isComposeMessageModal: false,
  openedDraft: null
}

const inboxReducer = (state = initialState, { type, payload }) => {
  const templateIndex = state.templates.findIndex(
    (template) => template.id === payload?.id
  )

  switch (type) {
    case ALL_TEMPLATES:
      return { ...state, templates: payload }
    case ADD_TEMPLATE:
      return { ...state, templates: [...state.templates, payload] }
    case EDIT_TEMPLATE:
      return {
        ...state,
        templates: state.templates.map((template, key) => {
          if (key === templateIndex) {
            return payload
          }
          return template
        })
      }
    case DELETE_TEMPLATE:
      return {
        ...state,
        templates: state.templates.filter((template) => template.id !== payload)
      }
    case SETUP_CHANNELS:
      return { ...state, setupChannels: payload }
    case OPENED_CONVERSATION:
      return { ...state, openedConversation: payload }
    case UNREAD_COUNT:
      return { ...state, unreadCount: payload }
    case IS_COMPOSE_MESSAGE_MODAL:
      return { ...state, isComposeMessageModal: payload }
    case OPENED_DRAFT:
      return { ...state, openedDraft: payload }
    default:
      return state
  }
}

export default inboxReducer
