import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Head from 'next/head'
import CommonApiRoutes from '@/api/common'
import { useGet } from '@/hooks/useGet'
import { setAllStaffs, setPermissions } from '@/store/actions/commonActions'
import Sidebar from '../common/sidebar'
import styles from './index.module.scss'
import Script from 'next/script'
import config from '@/config'

const CommonComponent = ({ children }) => {
  const dispatch = useDispatch()

  const {
    data: staffData,
    error: staffError,
    isLoading: isStaffLoading
  } = useGet(CommonApiRoutes.staff, {
    options: {
      enabled: true,
      staleTime: 60 * 1000
    }
  })

  const {
    data: permissionsData,
    error: permissionsError,
    isLoading: isPermissionsLoading
  } = useGet(CommonApiRoutes.permissions, {
    options: {
      enabled: true,
      staleTime: 60 + 10 * 1000
    }
  })

  useEffect(() => {
    if (staffData) {
      dispatch(setAllStaffs(staffData.data || []))
    }
  }, [staffData, dispatch])

  useEffect(() => {
    if (permissionsData) {
      dispatch(setPermissions(permissionsData.permissions || []))
    }
  }, [permissionsData, dispatch])

  const setWafToken = async () => {
    if (typeof window.AwsWafIntegration !== 'undefined') {
      if (!window.AwsWafIntegration.hasToken()) {
        await window.AwsWafIntegration.getToken()
      }
    }
  }

  return (
    <>
      <Head>
        <title>Bookee CRM | Client Management For Fitness Businesses</title>
        <meta
          name="description"
          content="The ultimate fitness studio CRM to manage scheduling, build marketing funnels, create billing cycles and scale your business"
        />
      </Head>
      <Script src={config.WAF_URL} onLoad={setWafToken} />
      <div className={styles.container}>
        {/* <Sidebar /> */}
        <div className={styles.main}>{children}</div>
      </div>
    </>
  )
}

export default CommonComponent
