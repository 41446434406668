import PropTypes from 'prop-types'
import styles from './index.module.scss'

const EmptyState = ({ icon, title, description, cta }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>{icon}</div>
      <h3 className={styles.title}>{title}</h3>
      {description && <p className={styles.description}>{description}</p>}
      {cta}
    </div>
  )
}

EmptyState.propTypes = {
  cta: PropTypes.any,
  description: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string
}

export default EmptyState
