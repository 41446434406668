import axios from 'axios'
import * as Sentry from '@sentry/browser'
import config from '@/config'
import { getAuthToken, getCenterId, getCookie, routeToLogin } from './utils'
import { API_METHODS } from './enums'
import { ROUTES } from './routes'

// Create an Axios instance with custom configuration
const api = axios.create({
  baseURL: `${config.BASE_URL}api`,
  timeout: 30000
})

api.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      'x-aws-waf-token': getCookie('aws-waf-token')
    }
    return config
  },
  (error) => {
    return window.Promise?.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([401, 403].includes(error.response?.status)) {
      routeToLogin()
      return
    }
    if (error.response?.status === 411) {
      window.location.href = ROUTES.noAccess
      return
    }
    Sentry.captureMessage(error.response?.data?.message, 'error', {
      extra: error.response?.data
    })
    return window.Promise?.reject(error?.response?.data)
  }
)

if (typeof window !== 'undefined') {
  api.defaults.params = {
    ...api.defaults.params,
    center_id: getCenterId()
  }
  api.defaults.headers.common = {
    ...api.defaults.headers.common,
    Authorization: `Bearer ${getAuthToken()}`
  }
}

/**
 * Sends a GET request to the specified `url` with the provided `params`.
 * Returns the response data on success or throws an error on failure.
 *
 * @param {string} url - The URL to send the request to.
 * @param {object} params - The parameters to include in the request.
 * @param {string} baseURL - Optional base URL to override the default one.
 * @returns {Promise<object>} - The response data on success.
 * @throws - An error on failure.
 */
export const get = async (url, params, baseURL) => {
  try {
    const response = await api.get(url, { params, baseURL })
    return response?.data
  } catch (error) {
    console.error(`GET request to ${url} failed: `, error)
    throw error
  }
}

/**
 * Sends a POST request to the specified `url` with the provided `data`.
 * Returns the response data on success or throws an error on failure.
 *
 * @param {string} url - The URL to send the request to.
 * @param {object} data - The data to include in the request.
 * @param {string} method - The HTTP method for the request (e.g., 'post', 'put', 'patch', etc.).
 * @param {string} baseURL - Optional base URL to override the default one.
 * @returns {Promise<object>} - The response data on success.
 * @throws - An error on failure.
 */
export const post = async (url, data, method = API_METHODS.post, baseURL) => {
  try {
    const response = await api[method]?.(url, data, { baseURL })
    return response?.data
  } catch (error) {
    console.error(`${method} request to ${url} failed: `, error)
    throw error
  }
}
