import {
  format,
  isBefore,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
  subDays
} from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

import {
  COOKIE_KEYS,
  DATA_TYPES,
  LOCALSTORAGE,
  MESSAGE_SCOPE_TYPES,
  MESSAGE_SENDER_TYPES,
  MESSAGE_VARIANTS
} from '../enums'
import { ROUTES } from '../routes'

/**
 * Truncates a value if it exceeds a certain limit, appending a suffix if necessary.
 * - If the value is a number and it exceeds the limit, the function returns the limit followed by the suffix.
 * - If the value is a string and its length exceeds the limit, the function returns the first `limit` characters of the string followed by the prefix.
 * - If the value is not a number or a string, the function returns the original value.
 * @param {number | string} value - The value to be truncated.
 * @param {number} limit - The maximum length of the value before it gets truncated.
 * @param {string} suffix - The string to be appended to the truncated value.
 * @returns The truncated value or the original value if it doesn't need to be truncated.
 */
export const getTruncatedValue = (value, limit, suffix) => {
  if (typeof value === DATA_TYPES.number) {
    if (value > limit) {
      return `${limit}${suffix}`
    }
  } else if (typeof value === DATA_TYPES.string) {
    if (value.length > limit) {
      return `${value.slice(0, limit)}${suffix}`
    }
  }
  return value
}

/**
 * classname helper function
 * @param {...string} args classes
 * @returns combined string of all the classes
 */
export const classNames = (...args) => {
  return args.filter(Boolean).join(' ')
}

/**
 * Get rounded number
 * @param {number} num Number to be rounded
 * @param {number} decimalPlaces number of places allowed after decimal
 * @returns the rounded number
 */
export const getRoundedNumber = (num, decimalPlaces = 0) => {
  const factor = Math.pow(10, decimalPlaces)
  return Math.round(num * factor) / factor
}

/**
 * Get floored number
 * @param {number} num Number to be floored
 * @param {number} decimalPlaces number of places allowed after decimal
 * @returns the floored number
 */
export const getFloorNumber = (num, decimalPlaces = 0) => {
  const factor = Math.pow(10, decimalPlaces)
  return Math.floor(num * factor) / factor
}

/**
 * Gets the percentage of a number
 * @param {number} num value
 * @param {number} max max allowed
 * @param {number} digits places to round to (default: 0)
 * @returns percentage in required format
 */
export const getPercentage = (num, max, digits = 0) => {
  if (max === 0) return 0

  if (max < num) return getRoundedNumber(100, digits)

  const percentage = (num / max) * 100

  return getFloorNumber(percentage, digits)
}

/**
 * Formats the percentage value
 * @param {number} num value
 * @param {number} max max allowed
 * @returns {number} formatted percentage value
 */
export const formatPercentage = (num, max) => {
  const percentage = getPercentage(num, max, 5)

  if (percentage === 0) {
    return '0%'
  } else if (percentage < 1) {
    return '<1%'
  } else {
    return Math.floor(percentage).toString().concat('%')
  }
}

/**
 * Get Message variant
 * @param {string} sender one of [MESSAGE_SENDER_TYPES]
 * @param {string} scope one of [MESSAGE_SCOPE_TYPES]
 * @returns one of [MESSAGE_VARIANTS]
 */
export const getMessageVariant = (sender, scope) => {
  if (sender === MESSAGE_SENDER_TYPES.contact) return MESSAGE_VARIANTS.secondary
  else if (scope === MESSAGE_SCOPE_TYPES.external)
    return MESSAGE_VARIANTS.primary
  return MESSAGE_VARIANTS.internal
}

/**
 * Checks search query exists inside search string
 * @param {string} sentence text
 * @param {string} search saarch query
 * @returns whether search query exists inside search string
 */
export const searchInString = (sentence = '', search = '') => {
  const sentenceCleaned = sentence.trim().toLowerCase()
  const searchCleaned = search.trim().toLowerCase()

  if (!sentenceCleaned) return false

  return (
    sentenceCleaned.includes(searchCleaned) ||
    searchCleaned.includes(sentenceCleaned)
  )
}

/**
 * Localstorage helper functions
 * @returns {func, func, func, func} set, get, remove, clear
 */
export const localStorageHelper = {
  // Set an item in localStorage
  set(key, value) {
    if (typeof window === 'undefined') return
    localStorage.setItem(key, JSON.stringify(value))
  },

  // Get an item from localStorage
  get(key) {
    if (typeof window === 'undefined') return null
    const item = window?.localStorage.getItem(key)
    return item ? JSON.parse(item) : null
  },

  // Remove an item from localStorage
  remove(key) {
    if (typeof window === 'undefined') return null
    window?.localStorage.removeItem(key)
  },

  // Clear all items from localStorage
  clear() {
    if (typeof window === 'undefined') return null
    window?.localStorage.clear()
  }
}

/**
 * Get user from localstorage
 * @returns user
 */
export const getCurrentUser = () =>
  localStorageHelper.get(LOCALSTORAGE.currentUser)

/**
 * Get auth token from localstorage
 * @returns Bearer token
 */
export const getAuthToken = () => getCookie(COOKIE_KEYS.token)

/**
 * Get center ID from localstorage
 * @returns Center ID
 */
export const getCenterId = () => getCookie(COOKIE_KEYS.centerId)

/**
 * gets cookie value
 * @param {string} key cookie key
 * @returns cookie value
 */
export const getCookie = (key) => {
  if (typeof document === 'undefined') return null
  const cookies = document.cookie?.split(';')
  for (const elem of cookies) {
    const cookie = elem.trim()
    if (cookie.startsWith(key + '=')) {
      return cookie.substring((key + '=').length, cookie.length)
    }
  }
}

/**
 * Route to login page
 */
export const routeToLogin = () => {
  window.location.href = ROUTES.login
}

/**
 * Duplicate object with default values
 * @param {object} obj
 * @param {any} defaultValue
 * @returns copy of the obejct with default values
 */
export const nestedObjectWithDefaultValues = (obj, defaultValue) => {
  const newObj = {}

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && !Array.isArray(value)) {
      // If the value is an object, recursively call the function with the nested object
      newObj[key] = nestedObjectWithDefaultValues(value, defaultValue)
    } else {
      // If the value is a leaf node, set the default value
      newObj[key] = defaultValue
    }
  }

  return newObj
}

/**
 * Debounce a function
 * @param {func} func Function to be debounced
 * @param {number} delay Delay
 * @returns debounced function
 */
export const debounce = (func, delay) => {
  let timeoutId

  return function debouncedFunction(...args) {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

/**
 * converts "2023-04-19T13:36:11.000000Z" to readable format
 * @param {string} date Date time string
 * @returns formatted string in readable format
 */
export const getFormattedDateTime = (date) => {
  const dateTime = new Date(date)
  const timezone = getCookie(COOKIE_KEYS.timezone) ?? 'America/Detroit'
  const weekday = format(utcToZonedTime(dateTime, timezone), 'EEEE')

  if (isToday(dateTime)) {
    return `Today ${format(utcToZonedTime(dateTime, timezone), 'hh:mmaaa')}`
  } else if (isYesterday(dateTime)) {
    return `Yesterday ${format(utcToZonedTime(dateTime, timezone), 'hh:mmaaa')}`
  } else if (isThisWeek(dateTime)) {
    return `${weekday} ${format(
      utcToZonedTime(dateTime, timezone),
      'hh:mmaaa'
    )}`
  } else if (isThisYear(dateTime)) {
    return format(utcToZonedTime(dateTime, timezone), 'MMM dd, hh:mmaaa')
  }
  return format(utcToZonedTime(dateTime, timezone), 'MMM dd, yyyy, hh:mmaaa')
}

/**
 * @param {obj} param
 * @returns full name
 */
export const getUserName = (user, showDefault = true) => {
  const { first_name = '', last_name = '', name = '' } = user ?? {}
  return (
    name || `${first_name || (showDefault ? 'User' : '')} ${last_name}`.trim()
  )
}

/**
 * capitalizes first words of the sentence
 * @param {string} sentence sentence ot be capitalized
 * @returns capitalized case
 */
export const capitalizeFirstWords = (sentence) => {
  const words = sentence.split(' ')
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  )
  return capitalizedWords.join(' ')
}

/**
 * returns whether given date is older than a day
 * @param {date} dateToCheck Date to check
 */
export const isOlderThan1Day = (dateToCheck, baseDate) => {
  // Create a Date object for 1 day ago
  const oneDayAgo = subDays(baseDate ? new Date(baseDate) : new Date(), 1)

  const timezone = getCookie(COOKIE_KEYS.timezone) ?? 'America/Detroit'

  // Check if the date to be checked is before 1 day ago
  return isBefore(utcToZonedTime(dateToCheck, timezone), oneDayAgo)
}

/**
 * scrolls to the bottom of a div
 * @param {Ref} divRef Ref of the div
 */
export const scrollToBottom = (divRef) => {
  if (divRef.current) {
    divRef.current.scrollTop = divRef.current.scrollHeight
  }
}

/**
 * Converts an array of objects to an object of objects with key as id
 * @param {array} arr
 * @returns an object of objects
 */
export const arrayToObject = (arr, key) => {
  return arr.reduce((obj, item) => {
    obj[item[key || 'id']] = { ...item }
    return obj
  }, {})
}

/**
 * Generate an array of size [count] filled with fillWith or '0'
 * @param {number, fillWith} count Number of empty slots in an array
 * @returns new empty array
 */
export const generateEmptyArray = ({ count = 10, fillWith = 0 } = {}) =>
  new Array(count).fill(fillWith)

/**
 * Generate an array of keys filled with fillWith or '0'
 * @param {array} keyArray array of keys for the empty object
 * @returns new empty object
 */
export const generateEmptyObject = ({ keyArray, fillWith = 0 } = {}) =>
  keyArray.reduce((acc, curr) => ({ ...acc, [curr]: fillWith }), {})

/**
 * Function to check if phone prefix is valid and format phone number
 * @param {string} prefix phone prefix
 * @param {string} phone phone number
 * @returns formatted phone number
 */
export const checkPhonePrefixAndFormat = (prefix, phone) => {
  if (phone && prefix) {
    if (prefix.includes('+')) {
      // if prefix already has a + sign (+1, +91, etc.)
      return prefix + ' ' + phone // prepend prefix to phone
    } else {
      // if prefix does not have a + sign (1, 91, etc.)
      return '+' + prefix + ' ' + phone // prepend + sign and prefix to phone
    }
  }
  return phone
}
