export default {
  IS_PRODUCTION: false,
  BASE_URL: 'https://data.dev.bookeeapp.com/',
  PUSHER_KEY: '661d4be379f71c3f0ef5',
  LOG_ROCKET: true,
  INTERCOM_APP_ID: 'lzcn0xd6',
  ENV_NAME: 'dev',
  WEBSITE_URL: 'https://crm.dev.bookeeapp.com/',
  AI_BASE_URL: 'https://anton.stage.bookeeapp.com'
}
