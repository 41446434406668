import BaseIcon from './Base'

const IconApps = ({ className }) => {
  return (
    <BaseIcon
      width={24}
      height={24}
      viewBoxHeight={20}
      viewBoxWidth={20}
      className={className}
    >
      <g clipPath="url(#xwz1bvx5ia)">
        <path
          d="m18.25 7.408-.875-3.641A1.637 1.637 0 0 0 15.783 2.5H4.208c-.75 0-1.408.525-1.583 1.267L1.75 7.408c-.2.85-.017 1.717.517 2.4.066.092.158.159.233.242v5.783c0 .917.75 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V10.05c.075-.075.167-.15.233-.233a2.803 2.803 0 0 0 .517-2.409zm-2.492-3.25.875 3.642c.084.35.009.7-.208.975a.978.978 0 0 1-.783.392c-.509 0-.95-.409-1.009-.95l-.483-4.05 1.608-.009zm-4.925.009h1.634l.45 3.766a1.12 1.12 0 0 1-.275.892c-.184.217-.45.342-.792.342-.558 0-1.017-.492-1.017-1.092V4.167zM7.075 7.933l.458-3.766h1.634v3.908c0 .6-.459 1.092-1.075 1.092-.284 0-.542-.125-.742-.342a1.184 1.184 0 0 1-.275-.892zM3.367 7.8l.841-3.633H5.85l-.483 4.05c-.067.541-.5.95-1.009.95a.96.96 0 0 1-.775-.392 1.102 1.102 0 0 1-.216-.975zm.8 8.033v-5.025c.066.009.125.025.191.025.725 0 1.384-.3 1.867-.791.5.5 1.167.791 1.925.791.725 0 1.375-.3 1.858-.775a2.732 2.732 0 0 0 1.909.775c.7 0 1.366-.291 1.866-.791a2.608 2.608 0 0 0 1.867.791c.067 0 .125-.016.192-.025v5.025H4.167z"
          stroke="none"
        />
      </g>
      <defs>
        <clipPath id="xwz1bvx5ia">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </BaseIcon>
  )
}

export default IconApps
