import { SUBSCRIPTIONS } from '../types/billingTypes'

const initialState = {
  subscriptions: null
}

const billingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBSCRIPTIONS:
      return { ...state, subscriptions: payload }
    default:
      return state
  }
}

export default billingReducer
