import env from './env'

const defaultConfig = {
  ADYEN_MODE: 'test',
  CHARGEBEE: 'bookeeapp-test',
  POSTHOG_API_KEY: 'phc_lY4TDHjq0AISfKcZbY1xqFKU4hFijQt0cPIpuvNF9qk',
  IS_PRODUCTION: true,
  WAF_URL:
    'https://5e517f4d6897.ap-south-1.sdk.awswaf.com/5e517f4d6897/3a6c126da886/challenge.js',
  FLATFILE_KEY: 'sk_2e49e954031d4be8913e0cab624ae94d'
}

const config = {
  ...defaultConfig,
  ...env
}

export default config
