import BaseIcon from './Base'

const IconBookee = () => {
  return (
    <BaseIcon width={36} height={36}>
      <g clipPath="url(#clip0_1239_21557)">
        <rect width="36" height="36" rx="4.5" fill="white" />
        <rect width="36" height="36" fill="#6941C6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6414 29.3954C16.1743 28.8212 16.5926 28.1512 16.874 27.4211C17.2005 26.5519 17.3564 25.6283 17.3332 24.7005V24.6523C17.3533 23.7326 17.1975 22.8174 16.874 21.9558C16.5947 21.2247 16.1762 20.5543 15.6414 19.9815C15.1374 19.4588 14.5278 19.0485 13.853 18.7777C13.1938 18.5068 12.4876 18.3677 11.7745 18.3684C10.9802 18.3378 10.1932 18.5297 9.50276 18.9221C8.90002 19.2918 8.37478 19.7742 7.95601 20.3426V14.2031H4.5V30.7918H7.95601V29.2269C8.39181 29.7239 8.90609 30.1468 9.47859 30.4788C10.1809 30.8613 10.975 31.0445 11.7745 31.0085C12.4956 31.0111 13.2101 30.872 13.8772 30.5992C14.5451 30.3286 15.1468 29.9181 15.6414 29.3954ZM13.6355 23.3041C13.7986 23.7433 13.8805 24.2082 13.8772 24.6765V24.7246C13.884 25.201 13.802 25.6745 13.6355 26.1211C13.4868 26.5119 13.2654 26.8713 12.9829 27.1804C12.718 27.4666 12.3973 27.696 12.0404 27.8546C10.9278 28.3481 9.62301 28.0765 8.80189 27.1804C8.52731 26.8609 8.31429 26.4936 8.17352 26.097C8.01042 25.6578 7.92854 25.1929 7.93184 24.7246V24.6765C7.92854 24.2082 8.01042 23.7433 8.17352 23.3041C8.31429 22.9075 8.52731 22.5402 8.80189 22.2207C9.61495 21.3106 10.9296 21.037 12.0404 21.5465C12.3973 21.7051 12.718 21.9345 12.9829 22.2207C13.2685 22.5366 13.4901 22.9045 13.6355 23.3041Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.694 29.1939C30.288 28.6235 30.7644 27.9425 31.0964 27.1892C31.4342 26.4118 31.6071 25.573 31.6042 24.7256V24.6773C31.6071 23.8299 31.4342 22.9911 31.0964 22.2137C30.7727 21.4619 30.3044 20.7808 29.7182 20.209C29.1199 19.6271 28.4135 19.1677 27.6387 18.8565C26.8077 18.5284 25.9209 18.3644 25.0273 18.3734C24.1239 18.367 23.2282 18.5394 22.3918 18.8806C21.6087 19.2006 20.8946 19.668 20.2882 20.2573C19.6942 20.8277 19.2177 21.5087 18.8858 22.262C18.5473 23.0392 18.3744 23.8781 18.378 24.7256V24.7739C18.3744 25.6214 18.5473 26.4604 18.8858 27.2375C19.2095 27.9894 19.6777 28.6705 20.264 29.2422C20.8623 29.8241 21.5687 30.2836 22.3434 30.5948C23.1745 30.9228 24.0613 31.0868 24.9548 31.0778C25.8582 31.0842 26.754 30.9118 27.5904 30.5706C28.3737 30.2511 29.088 29.7837 29.694 29.1939ZM27.953 23.4455C28.1022 23.8556 28.1759 24.2893 28.1707 24.7256L28.1465 24.7739C28.1482 25.2099 28.0745 25.643 27.9289 26.054C27.7912 26.446 27.5776 26.8071 27.3002 27.1167C27.0243 27.4251 26.6865 27.6719 26.3088 27.8413C25.8895 28.0233 25.4361 28.1139 24.979 28.107C24.5294 28.1158 24.0835 28.025 23.6733 27.8413C22.9016 27.4784 22.3027 26.8282 22.0049 26.0298C21.8558 25.6197 21.782 25.1861 21.7873 24.7497V24.7014C21.7856 24.2654 21.8592 23.8324 22.0049 23.4213C22.1426 23.0293 22.3562 22.6682 22.6336 22.3586C22.9094 22.0503 23.2472 21.8034 23.6249 21.634C24.0443 21.452 24.4976 21.3615 24.9548 21.3683C25.4042 21.3604 25.85 21.4511 26.2605 21.634C27.0429 21.9897 27.6517 22.6413 27.953 23.4455Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1239_21557">
          <rect width="36" height="36" rx="4.5" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  )
}

export default IconBookee
