import { memo, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { classNames } from '@/services/utils'
import IconBookee from '../icons/IconBookee'
import styles from './index.module.scss'
import IconReport from '../icons/IconReport'
import IconHome from '../icons/IconHome'
import IconMarketing from '../icons/IconMarketing'
import IconCalendar from '../icons/IconCalendar'
import IconCart from '../icons/IconCart'
import IconInbox from '../icons/IconInbox'
import IconSetup from '../icons/IconSetup'
import IconApps from '../icons/IconApps'
import IconSettings from '../icons/IconSettings'
import config from '@/config'
import IconUsers from '../icons/IconUsers'

const websiteUrl = config.WEBSITE_URL

const MENU_ITEMS = {
  home: 'home',
  reports: 'reports',
  sell: 'sell',
  calendar: 'calendar',
  marketing: 'marketing',
  inbox: 'inbox',
  contacts: 'contacts',
  setup: 'setup',
  apps: 'apps',
  settings: 'settings'
}

const Sidebar = () => {
  const [selectedItem, setSelectedItem] = useState(MENU_ITEMS.inbox)

  const permissions = useSelector((state) => state.common.permissions)

  const mainSidebarData = useMemo(
    () => [
      {
        id: MENU_ITEMS.home,
        icon: (
          <a href={`${websiteUrl}#/client/dashboard`}>
            <IconHome />
          </a>
        ),
        tooltip: 'Home',
        permission: true
      },
      {
        id: MENU_ITEMS.reports,
        icon: (
          <a href={`${websiteUrl}#/client/reports`}>
            <IconReport />
          </a>
        ),
        tooltip: 'Reports',
        permission: permissions[MENU_ITEMS.reports]?.is_assigned
      },
      {
        id: MENU_ITEMS.sell,
        icon: (
          <a href={`${websiteUrl}#/client/pos`}>
            <IconCart height={24} width={24} className={styles.icon} />
          </a>
        ),
        tooltip: 'Sell',
        permission: permissions[MENU_ITEMS.contacts]?.is_assigned
      },
      {
        id: MENU_ITEMS.calendar,
        icon: (
          <a href={`${websiteUrl}#/client/calendar`}>
            <IconCalendar className={styles.icon} height={24} width={24} />
          </a>
        ),
        tooltip: 'Calendar',
        permission: permissions[MENU_ITEMS.calendar]?.is_assigned
      },
      {
        id: MENU_ITEMS.marketing,
        icon: (
          <a href={`${websiteUrl}#/client/campaigns`}>
            <IconMarketing />
          </a>
        ),
        tooltip: 'Marketing',
        permission: permissions[MENU_ITEMS.marketing]?.is_assigned
      },
      {
        id: MENU_ITEMS.inbox,
        icon: (
          <a href="/app/inbox">
            <IconInbox className={styles.icon} />
          </a>
        ),
        tooltip: 'Inbox',
        permission: true
      },
      {
        id: MENU_ITEMS.contacts,
        icon: (
          <a href={`${websiteUrl}#/client/customers`}>
            <IconUsers />
          </a>
        ),
        tooltip: 'Contacts',
        permission: permissions[MENU_ITEMS.contacts]?.is_assigned
      },
      {
        id: MENU_ITEMS.setup,
        icon: (
          <a href={`${websiteUrl}#/client/services`}>
            <IconSetup />
          </a>
        ),
        tooltip: 'Setup',
        permission: permissions[MENU_ITEMS.setup]?.is_assigned
      }
    ],
    [permissions]
  )

  const footerSidebarData = useMemo(
    () => [
      {
        id: MENU_ITEMS.apps,
        icon: (
          <a href={`${websiteUrl}#/client/apps`}>
            <IconApps className={styles.iconApps} />
          </a>
        ),
        tooltip: 'Apps',
        permission: permissions[MENU_ITEMS.contacts]?.is_assigned
      },
      {
        id: MENU_ITEMS.settings,
        icon: (
          <a href={`${websiteUrl}#/client/settings`}>
            <IconSettings className={styles.icon} />
          </a>
        ),
        tooltip: 'Settings',
        permission: permissions[MENU_ITEMS.settings]?.is_assigned
      }
    ],
    [permissions]
  )

  const handleItemClick = (id) => {
    setSelectedItem(id)
  }

  const memoizedMainSidebarData = useMemo(
    () => mainSidebarData.filter((item) => item.permission),
    [mainSidebarData]
  )
  const memoizedFooterSidebarData = useMemo(
    () => footerSidebarData.filter((item) => item.permission),
    [footerSidebarData]
  )

  return (
    <div className={styles.sidebar}>
      <a href={websiteUrl}>
        <IconBookee />
      </a>
      <div className={styles.content}>
        <ul className={styles.main}>
          {memoizedMainSidebarData.map(({ id, icon, tooltip }) => (
            <MemoizedSidebarItem
              key={id}
              id={id}
              icon={icon}
              tooltip={tooltip}
              isSelected={selectedItem === id}
              handleItemClick={handleItemClick}
            />
          ))}
        </ul>
        <ul className={styles.footer}>
          {memoizedFooterSidebarData.map(({ id, icon, tooltip }) => (
            <MemoizedSidebarItem
              key={id}
              id={id}
              icon={icon}
              tooltip={tooltip}
              isSelected={selectedItem === id}
              handleItemClick={handleItemClick}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}

const SidebarItem = ({ id, icon, tooltip, isSelected, handleItemClick }) => (
  <li
    className={classNames(styles.item, isSelected && styles.active)}
    onClick={() => handleItemClick(id)}
    title={tooltip}
  >
    {icon}
  </li>
)

const MemoizedSidebarItem = memo(SidebarItem)

export default Sidebar
