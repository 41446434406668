import { ALL_STAFFS, PERMISSIONS } from '../types/commonTypes'

export const setAllStaffs = (staffs) => ({
  type: ALL_STAFFS,
  payload: staffs
})

export const setPermissions = (permissions) => ({
  type: PERMISSIONS,
  payload: permissions
})
