import { captureException } from '@sentry/nextjs'
import { Component } from 'react'
import styles from './index.module.scss'
import Button from '../common/button'
import { BUTTON_VARIANTS } from '@/services/enums'
import EmptyState from '../common/empty-state'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo })

    captureException(errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <EmptyState
            title="Oops, something went wrong!"
            description="We are looking into it."
            cta={
              <Button
                onClick={() => this.setState({ hasError: false })}
                variants={BUTTON_VARIANTS.primary}
              >
                Try again
              </Button>
            }
          />
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
