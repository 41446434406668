import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BUTTON_VARIANTS, LOADER_VARIANTS } from '@/services/enums'
import styles from './index.module.scss'
import { classNames } from '@/services/utils'

const ButtonToLoaderMap = {
  [BUTTON_VARIANTS.primary]: LOADER_VARIANTS.secondary,
  [BUTTON_VARIANTS.secondary]: LOADER_VARIANTS.primary
}

const Spinner = ({ variant, buttonVariant, color, className }) => {
  const styledButtonVariant = useMemo(() => {
    if (Array.isArray(buttonVariant)) {
      if (buttonVariant.includes(BUTTON_VARIANTS.secondary)) {
        return BUTTON_VARIANTS.secondary
      } else {
        return BUTTON_VARIANTS.primary
      }
    }
    return buttonVariant
  }, [buttonVariant])

  return (
    <div className={classNames(styles.spinner, className)}>
      <div
        className={classNames(
          styles.spinnerInner,
          styles[
            variant ||
              ButtonToLoaderMap[styledButtonVariant] ||
              LOADER_VARIANTS.primary
          ]
        )}
        style={{ borderTopColor: color, borderRightColor: color }}
      />
    </div>
  )
}

Spinner.propTypes = {
  buttonVariant: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(BUTTON_VARIANTS)))
  ]),
  color: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(LOADER_VARIANTS))
}

export default Spinner
